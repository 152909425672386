<template>
  <div class="main">
    <van-collapse v-model="activeNames">
      <van-collapse-item title="查询条件" name="1">
        <van-row>
          <van-col span="24">
            <van-search
              v-model="showQuery.area"
              label="作业区域"
              placeholder="请选择"
              @click-input="onSearch('area')"
            >
            </van-search>
          </van-col>
        </van-row>
        <van-row>
          <van-col span="24">
            <van-search
              v-model="showQuery.ptw_cetificate_type_id"
              label="许可证种类"
              placeholder="请选择"
              @click-input="onSearch('ptw_cetificate_type_id')"
            >
            </van-search>
          </van-col>
        </van-row>
        <van-row>
          <van-col span="24">
            <van-search
              v-model="showQuery.applicant"
              label="初始申请人"
              placeholder="请选择"
              @click-input="onSearch('applicant')"
            >
            </van-search>
          </van-col>
        </van-row>
        <van-row>
          <van-col span="24">
            <van-search
              v-model="showQuery.status"
              label="状态"
              placeholder="请选择"
              @click-input="onSearch('status')"
            >
            </van-search>
          </van-col>
        </van-row>
      </van-collapse-item>
    </van-collapse>
    <!-- 查询显示的数据 -->
    <div v-if="selectData.length > 0" class="PTWLIST">
      <van-pull-refresh
        v-model="loading"
        @refresh="onRefresh"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-card
          v-for="(items, index) in selectData"
          :key="index"
          :title="items.code + '(' + items.organization_name + ')'"
        >
          <template #desc>
            <div class="descBox">
              <van-cell
                title="作业许可种类"
                :value="items.ptw_cetificate_type_id"
              />
              <van-cell
                title="作业装置/区域"
                :value="items.ptw_unit_id + '/' + items.area"
              />
              <van-cell title="作业开始时间" :value="items.start_date" />
              <van-cell title="作业结束时间" :value="items.end_date" />
              <van-cell title="当前状态" :value="items.status" />
            </div>
          </template>
          <!-- 查看详情点击跳转页面 -->
          <template #footer>
            <van-button size="small" :to="'/ptwDetail/' + items.id"
              >查看详情</van-button
            >
          </template>
        </van-card>
      </van-pull-refresh>
    </div>
    <!-- 模糊查询内容popup -->
    <van-popup
      v-model:show="searchPopup.show"
      position="bottom"
      :style="{ height: '80%' }"
    >
      <div class="showBg">
        <van-cell-group inset>
          <van-field
            clearable
            v-model="searchPopup.value"
            label="请选择"
            arrow-direction="down"
            placeholder="请选择"
          ></van-field>
        </van-cell-group>
        <ul>
          <li
            v-for="(item, index) in list"
            :key="index"
            class="liheight"
            @click="checkNum(item)"
          >
            {{ item.label }}
          </li>
        </ul>
      </div>
    </van-popup>
  </div>
</template>
<script>
/* eslint-disable */
import {onMounted,ref,reactive,watch,getCurrentInstance} from 'vue';
import {Dialog,Toast,Popup } from 'vant';
import {useRoute} from 'vue-router';
import Http from '../../../api/http';
export default {
    setup() {
        const finished = ref(false);
        const activeNames = ref(['0']);
        const dataList=ref([])         //后台数据 ])
        const list=ref([]) //渲染的数据  
        const searchPopup = reactive({
            value:'',
            show:false
        });
        const selectData=ref([])   //查询后的数据数组
        // 查询条件
        const query = reactive({
            method:"query",
            area:"",
            ptw_cetificate_type_id:"",
            status:"",
            applicant:"",
            queryId:186,
            page:1,
            pageSize:10
        });
        const  count=ref(0)  //总数
        // 显示文本内容
        const showQuery = reactive({
            area:'',
            applicant:"",
            ptw_cetificate_type_id:"",
            status:"",
        })
        //区域模糊查询
        const key = ref('')
        const onSearch = (v) =>{
            key.value = v;
            Http.post({
                method:"listPardata",
                t:'PTW_CETIFICATE',
                prop:v
            })
            .then(res=>{
                //成功后赋值给自己默认的数组
                dataList.value=res;
                searchPopup.show = true;
            })
            .catch(()=>{});
        };
        //输入框有值后
        watch(()=>searchPopup.value,(newval,oldval)=>{
            list.value= [];
            if(newval!=oldval){
                if(newval!=''){
                    dataList.value.map((item)=>{
                        //拿当前json的value去分别跟输入的值进行比较
                        //indexOf 如果在检索的字符串中没有出现要找的值是会返回-1的，所以我们这里不等于-1就是假设输入框的值在当前json里面找到的情况
                        if(item.label.search(newval)!=-1){
                            //然后把当前json添加到list数组中
                            list.value.push(item);
                        }
                    })
                }
            }
        });
    
        //监听弹出层
        watch(()=>searchPopup.show,(newval)=>{
            if(newval == true){
                list.value= [];
                searchPopup.value = '';
            }
        })
        //监听query对象的value是否发生变化，如果改变则调接口取数据
        watch(() => query,(state,prevState) =>{updata()},{ deep: true });
        //加载等模块
        const loading = ref(false);
        const onRefresh = () =>{
            selectData.value = [];
            finished.value = false;
            loading.value = true;
            query.page = 1;
            updata()
        }
        const onLoad=()=>{
            if(finished.value == false && count.value > selectData.value.length){
                query.page = query.page + 1;
                updata();
            }else{
                finished.value = true;
            }
        }
        //查询出来的点击赋值
        const checkNum=(item)=>{
            query[key.value] = item.value;
            showQuery[key.value] = item.label;
            searchPopup.show = false;
        }
        // 点击搜索请求接口
        const updata=()=>{
            Http.post(query)
            .then(res=>{
                console.log(res)
                //赋值给显示的参数
                loading.value = false;
                selectData.value=[]
                count.value=res.totalCount
               if(query.page == 1){
                   selectData.value = res.map
               }else{
                   selectData.value.concat(res.map);
               }
            })
            .catch(()=>{
                  loading.value = false;
            });
           
        }
        onMounted(()=>{
            updata()
        })
          
        
        return {
            onSearch,
            list,
            dataList,
            checkNum,
            searchPopup,
            query,
            showQuery,
            updata,
            selectData,
            prompt,
            activeNames,
            loading,
            onRefresh,
            finished,
            onLoad
        }
    }
}
</script>
<style lang="less" scoped>
    .yangshi{
        height: 20px;
        font-size: 16px;
    }
    .showBg{
        background:#ffffff;
        margin-top: 10px;
    }
    .liheight{
        height: 30px;
        font-size: 16px;
        border: 1px solid blue !important;;
        line-height: 30px;
        margin-top: 1px;
        
    }
    .search{
        width: 60%;
        margin: 5px;
        margin-left: 55px;
    }
   .content_right{
       text-align: right;
   }
</style>